import classes from "./FarmCards.module.css";
import {
  Processing,
  TokenInput,
  useModalByName,
} from "@curiodao/capital-dex-ui-kit";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { usePoolBalances } from "../../hooks/usePoolBalances";
import { formatUnits } from "ethers";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import { useHarvest } from "../../hooks/useHarvest";
import { StakingModal } from "../StakingModal";
import { IFarmPool } from "@curiodao/capital-dex-sdk/evm";
import Skeleton from "react-loading-skeleton";
import React from "react";
import { GetFuelModal } from "../../../../../components/GetFuelModal";
import { getShowBalance } from "../../../../../common/libs/getShowBalance";
import { useSkaleFuelBalance } from "../../../../../common/_evm/hooks/useSkaleFuelBalance";

export const FarmCards = ({ farmPool }: { farmPool: IFarmPool }) => {
  const { address } = useWeb3ModalAccount();
  const { changeModal, modal, closeModal } = useModalByName();
  const {
    staked,
    balance,
    loading: loadingBalances,
  } = usePoolBalances(farmPool, address);
  const {
    harvest,
    rewards,
    isHarvesting,
    loading: loadingHarvest,
  } = useHarvest(farmPool, address);
  const { skaleNativeBalance, skaleNative, isShowFuel } = useSkaleFuelBalance();

  return (
    <div>
      <div className={classNames(classes.Earned, "card")}>
        <p className="p1 semi">{farmPool.rewardToken.symbol} Earned</p>
        {loadingHarvest ? (
          <Skeleton height={70} count={1} />
        ) : (
          <div className="card bordered">
            <TokenInput
              token0={farmPool.rewardToken}
              value={parseFloat(formatUnits(rewards)).toFixed(4)}
            />
          </div>
        )}
        <button
          className="btn block"
          onClick={() => harvest()}
          disabled={isHarvesting || !(rewards && rewards > 0n)}
        >
          {isHarvesting ? <Processing /> : "Harvest"}
        </button>
      </div>
      <div className="card">
        <p className="p1 semi">
          {farmPool.token0.symbol}-{farmPool.token1.symbol} CLP tokens staked
        </p>
        {loadingBalances ? (
          <Skeleton height={97} count={1} />
        ) : (
          <div className="card bordered">
            <TokenInput
              token0={farmPool.token0}
              token1={farmPool.token1}
              value={parseFloat(formatUnits(staked)).toFixed(4)}
            />
            <p className="p3">
              Balance {`${farmPool.token0.symbol}-${farmPool.token1.symbol}`}:{" "}
              {parseFloat(formatUnits(balance)).toFixed(4)}
            </p>
          </div>
        )}
        {isShowFuel ? (
          <>
            <button
              className={"btn block"}
              onClick={() => changeModal("get-sFUEL")}
            >
              Get sFUEL
            </button>
            <p className="p3 regular color-gray">
              You need sFUEL to perform this action
            </p>
          </>
        ) : balance === 0n && staked === 0n ? (
          <Link
            className="btn block"
            to={`/pools/add/${farmPool.token0.symbol}-${farmPool.token1.symbol}`}
          >
            Add liquidity
          </Link>
        ) : (
          <div className={classes.Btns}>
            {staked !== 0n && (
              <button
                className="btn secondary block"
                onClick={() => changeModal("withdraw")}
                disabled={modal === "withdraw"}
              >
                {modal === "withdraw" ? <Processing /> : "Unstake"}
              </button>
            )}
            {staked !== balance && (
              <button
                className="btn block"
                onClick={() => changeModal("deposit")}
                disabled={modal === "deposit"}
              >
                {modal === "deposit" ? <Processing /> : "Stake"}
              </button>
            )}
          </div>
        )}
      </div>
      {(modal === "deposit" || modal === "withdraw") && (
        <StakingModal
          farmPool={farmPool}
          balance={balance}
          onCloseButtonClick={closeModal}
          isDeposit={modal === "deposit"}
          staked={staked}
        />
      )}
      {modal === "get-sFUEL" && (
        <GetFuelModal
          balance={getShowBalance(skaleNativeBalance, skaleNative.decimals)}
          onCloseButton={closeModal}
        />
      )}
    </div>
  );
};
