import React, { useEffect, useState, JSX } from "react";
import classes from "./Header.module.css";
import { Logo, SmallLogo, useModal } from "@curiodao/capital-dex-ui-kit";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Wallet } from "../Wallet";
import { Networks } from "../Networks";
import { useNetworkContext } from "../../../Network";
import { Menu } from "../Menu";
import { MobileMenu } from "../MobileMenu";
import { useSkaleFuelBalance } from "../../../../common/_evm/hooks/useSkaleFuelBalance";
import { GetFuelModal } from "../../../../components/GetFuelModal";
import { getShowBalance } from "../../../../common/libs/getShowBalance";

export const Header = (): JSX.Element => {
  const { isParachain, core, isTon } = useNetworkContext();
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 768) {
        setShowMenu(false);
      }
    });
  }, []);

  const { skaleNativeBalance, skaleNative } = useSkaleFuelBalance();
  const { network } = useNetworkContext();
  const { toggleModal, isShowing } = useModal();

  return (
    <div className={classes.HeaderWrapper}>
      <div className={classes.HeaderInner}>
        <div className={classes.LogoBlock}>
          <Link to="/">
            <Logo />
            <SmallLogo />
          </Link>
        </div>
        {!showMenu && (
          <Menu
            showMenu={showMenu}
            core={core}
            isParachain={isParachain}
            isTon={isTon}
          />
        )}
        <div className={classes.ButtonsWrap}>
          {network === "skale" && (
            <button className="btn small" onClick={toggleModal}>
              Get sFUEL
            </button>
          )}
          <Networks />
          <Wallet isParachain={isParachain} isTon={isTon} />
        </div>
        <button
          className={classNames(classes.MobileButton)}
          onClick={() => setShowMenu(!showMenu)}
        />
      </div>
      {showMenu && (
        <MobileMenu
          showMenu={showMenu}
          isParachain={isParachain}
          isTon={isTon}
          core={core}
          toggleModal={() => setShowMenu(!showMenu)}
        />
      )}
      {isShowing && (
        <GetFuelModal
          balance={getShowBalance(skaleNativeBalance, skaleNative.decimals)}
          onCloseButton={toggleModal}
        />
      )}
    </div>
  );
};
