import { useEffect, useState } from "react";
import { TokenService } from "@curiodao/capital-dex-sdk/evm";
import { useBlock } from "../../../../common/hooks/useBlock";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { parseUnits } from "ethers";
import { IToken } from "@curiodao/capital-dex-sdk";

export const useAllowance = (
  value: string,
  spender?: string,
  token?: IToken,
  setIsPending?: (v: boolean) => void,
) => {
  const [allowance, setAllowance] = useState(0n);
  const [isApproving, setIsApproving] = useState(false);
  const block = useBlock();
  const { address } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const approve = async (amount: string) => {
    if (address && walletProvider && token && spender) {
      setIsApproving(true);
      const tokens = new TokenService(token, walletProvider);
      try {
        await tokens.approve(
          spender,
          walletProvider,
          parseUnits(amount, token.decimals).toString(),
        );
        if (setIsPending) {
          setIsPending(true);
        }
      } catch {
        setIsApproving(false);
      }

      setIsApproving(false);
    }
  };

  useEffect(() => {
    if (address && walletProvider && token && spender) {
      (async () => {
        if (token && !token.isNative) {
          const tokens = new TokenService(token, walletProvider);
          const allowance = await tokens.getAllowance(address, spender);

          setAllowance(allowance);
        }
      })();
    }
  }, [address, spender, token, walletProvider, block]);

  return {
    approve,
    isAllowance: token?.isNative
      ? true
      : value.length
        ? parseUnits(value, token?.decimals) <= allowance
        : false,
    isApproving,
  };
};
